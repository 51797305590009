import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
import Catalogo2022 from '@/views/Catalogo2022';
import Catalogo2023 from '@/views/Catalogo2023';
import Podcast2022 from '@/views/Podcast2022';
import Podcast2023 from '@/views/Podcast2023';
import ModalOverlay from '@/components/ModalOverlay';

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      children: [
        {
          path: 'video/:videoId',
          name: 'ModalOverlay',
          component: ModalOverlay
        }
      ]
    },
    {
      path: '/chi-siamo/',
      name: 'about',
      component: About
    },
    {
      path: '/settimana-razzismo',
      name: 'settimana-razzismo',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/SettimanaRazzismo.vue')
    },
    {
      path: '/biblioteca-vivente',
      name: 'biblioteca-vivente',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/Biblioteca.vue')
    },
    {
      path: '/catalogo/2022',
      name: 'Catalogo2022',
      component: Catalogo2022
    },
    {
      path: '/catalogo/2023',
      name: 'Catalogo2023',
      component: Catalogo2023
    },
    {
      path: '/podcast/2022',
      name: 'Podcast2022',
      component: Podcast2022,
      children: [
        {
          path: 'video/:videoId',
          name: 'ModalOverlayArchive',
          component: ModalOverlay
        }
      ]
    },
    {
      path: '/podcast/2023',
      name: 'Podcast2023',
      component: Podcast2023,
      children: [
        {
          path: 'video/:videoId',
          name: 'ModalOverlayArchive2023',
          component: ModalOverlay
        }
      ]
    }
  ]
})
