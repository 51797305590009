<template>
  <v-container class="fill-height pa-6">
    <v-row class="align-center">
      <v-col class="col-12">
        <h1 class="display-3 text-uppercase mb-4">Chi siamo</h1>
        <p>Associazione 21 luglio è un’organizzazione non profit che supporta gruppi e individui in condizione di segregazione estrema e di discriminazione.</p>

<p>Insieme all’Università degli Studi di Roma Tor Vergata e a Psicoanalisi Contro, gestisce il Polo ex Fienile di Tor Bella Monaca, un presidio di cultura, educazione e socialità.
È al Polo ex Fienile di Tor Bella Monaca che nasce “Un libro non si giudica dalla copertina!”, sedici storie di discriminazione razziale e di genere raccontate da coloro che le hanno vissute.
I protagonisti, consapevoli di appartenere a categorie soggette a stereotipi e pregiudizi, hanno scelto di scardinarli! E di condividere le loro storie.</p>

<h3>Edizione 2022:</h3>

<p>Nezgiba Salkanovic, Fatou Ndiaye Diop, Leidy Parada, Fatoumata Ndiaye, Beatrice Atieno Ragot, Kwanza Musi Dos Santos, Akossiwa Sheillea Anani e Zeneb, le autrici.
Regia di Alizé Van Reeth e Irene Aurora Paci, missaggio di Irene Aurora Paci, coregia di Agnese Vannozzi, Sofia Bertoncini e Piero Vereni, produzione, fotografia e progetto grafico di Koda Media.</p>

<h3>Edizione 2023:</h3>

<p>Eriselda Qejvanaj, Ruth Omobude, Samira Arouadi, Michèle Djitheu, Hanane Elkani, Svetlana Salkanovic, Shila Gladys e Sajjad, gli autori.
Regia e montaggio di Alizè Van Reeth, missaggio di Daniele Bartolini, coregia e produzione di Agnese Vannozzi, fotografia di Ersilia Tarullo, progetto grafico di Carlo Alberto Baldi, con la partecipazione di Francesca Batistoni, Allison Acton e Valeria Rotili.
Per la sigla, un ringraziamento speciale a Irene Aurora Paci e Horst Du Noch.</p>

<p>“Un libro non si giudica dalla copertina!” è un progetto di Associazione 21 luglio, realizzato con il sostegno di UNAR, Ufficio Nazionale Antidiscriminazioni Razziali.</p>
          <v-btn class="mt-3"
          large
          tile
          elevation="0"
          color="primary"
          ma-2
          href="https://www.21luglio.org"
          target="_blank"
        >Visita il sito 21 LUGLIO
        <v-icon
        right
        dark
      >
        mdi-earth
      </v-icon> </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AboutIndex',
  props: ['drawer']
}
</script>
