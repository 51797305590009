<template>
  <v-sheet>
    <router-view></router-view>
    <VueSlickCarousel :arrows="true" :autoplay="true" :dots="true" :slidesToShow="2" v-bind="settings">
      <v-card 
      transparent 
      elevation="0" 
      class="fill-height px-3"
      @click="$router.push({name:'ModalOverlayArchive' , params:{videoId: 'zeneb.mp4',posterId: 'zeneb.png' }})"
      >
      <v-img height="100%" src="@/assets/zeneb.png"></v-img>
        <div class="aut-name">
          <v-btn
            fab
            height="30px"
            right
            width="30px"
            color="blue-grey darken-3"
            elevation="0"
            dark
            @click="$router.push({name:'ModalOverlayArchive' , params:{videoId: 'zeneb.mp4',posterId: 'zeneb.png' }})"
          >
          <v-icon>mdi-play</v-icon>
          </v-btn>
          <h2 class="text-uppercase">Ma Ero Femmina</h2>
          <h3>Zeneb</h3>
        </div>
      </v-card>
      
      <v-card 
      transparent 
      elevation="0" 
      class="fill-height px-3"
      @click="$router.push({name:'ModalOverlayArchive' , params:{videoId: 'sheillea.mp4',posterId: 'sheillea.png' }})"
       >
        <v-img height="100%" src="@/assets/sheillea.png"></v-img>
          <div class="aut-name">
            <v-btn
            fab
            height="30px"
            right
            width="30px"
            color="blue-grey darken-3"
            elevation="0"
            dark
            @click="$router.push({name:'ModalOverlayArchive' , params:{videoId: 'sheillea.mp4',posterId: 'sheillea.png' }})"
          >
          <v-icon>mdi-play</v-icon>
          </v-btn>
          <h2 class="text-uppercase">Raccontamela giusta!</h2>
          <h3>Akossiwa Sheillea Anani</h3>
        </div>
      </v-card>

      <v-card 
      transparent 
      elevation="0" 
      class="fill-height px-3"
      @click="$router.push({name:'ModalOverlayArchive' , params:{videoId: 'fatoumata.mp4',posterId: 'fatoumata.png' }})"
      >
      <v-img height="100%" src="@/assets/fatoumata.png"></v-img>
      <div class="aut-name">
        <v-btn
          fab
          height="30px"
          right
          width="30px"
          color="blue-grey darken-3"
          elevation="0"
          dark
          @click="$router.push({name:'ModalOverlayArchive' , params:{videoId: 'fatoumata.mp4',posterId: 'fatoumata.png' }})"
        >
        <v-icon>mdi-play</v-icon>
        </v-btn>
        <h2 class="text-uppercase">Una giornata tranquilla</h2>
        <h3>Fatoumata Ndiaye</h3>
      </div>
      </v-card>

      <v-card 
      transparent 
      elevation="0" 
      class="fill-height px-3"
      @click="$router.push({name:'ModalOverlayArchive' , params:{videoId: 'kwanza.mp4',posterId: 'kwanza.png' }})"
       >
          <v-img height="100%" src="@/assets/kwanza.png"></v-img>
          <div class="aut-name">
            <v-btn
              fab
              height="30px"
              right
              width="30px"
              color="blue-grey darken-3"
              elevation="0"
              dark
              @click="$router.push({name:'ModalOverlayArchive' , params:{videoId: 'kwanza.mp4',posterId: 'kwanza.png' }})"
            >
            <v-icon>mdi-play</v-icon>
            </v-btn>
            <h2 class="text-uppercase">La banalità del razzismo</h2>
            <h3>Kwanza Musi Dos Santos</h3>
          </div>
      </v-card>

      <v-card
      class="fill-height px-3"
      transparent
      elevation="0"
      @click="$router.push({name:'ModalOverlayArchive' , params:{videoId: 'beatrice.mp4',posterId: 'beatrice.png' }})"
      >
          <v-img height="100%" src="@/assets/beatrice.png"></v-img>
          <div class="aut-name">
            <v-btn
              fab
              height="30px"
              right
              width="30px"
              color="blue-grey darken-3"
              elevation="0"
              dark
              @click="$router.push({name:'ModalOverlayArchive' , params:{videoId: 'nezgiba.mp4',posterId: 'nezgiba_b.jpg' }})"
          >
          <v-icon>mdi-play</v-icon>
          </v-btn>
          <h2 class="text-uppercase">Perché la mia famiglia mi tiene prigioniera?</h2>
          <h3>Beatrice Atieno Ragot</h3>
          </div>
      </v-card>

      <v-card transparent elevation="0" 
      class="fill-height px-3"
      @click="$router.push({name: 'ModalOverlayArchive', params:{videoId: 'leidy.mp4',posterId: 'leidy.png' }})"
      >
        <v-img height="100%" src="@/assets/leidy.png"></v-img>
          <div class="aut-name">
            <v-btn
              fab
              height="30px"
              right
              width="30px"
              color="blue-grey darken-3"
              elevation="0"
              dark
              @click="$router.push({name:'ModalOverlayArchive' , params:{videoId: 'leidy.mp4',posterId: 'leidy.png' }})"
          >
          <v-icon>mdi-play</v-icon>
          </v-btn>
            <h2 class="text-uppercase">Che nessuno dica che non abbiamo lottato</h2>
            <h3>Leidy Parada</h3>
          </div>
      </v-card>

      <v-card transparent elevation="0" 
      class="fill-height px-3"
      @click="$router.push({name: 'ModalOverlayArchive', params:{videoId: 'fatou.mp4',posterId: 'fatou.png' }})"
       >
        <v-img height="100%" src="@/assets/fatou.png"></v-img>
        <div class="aut-name">
          <v-btn
              fab
              height="30px"
              right
              width="30px"
              color="blue-grey darken-3"
              elevation="0"
              dark
              @click="$router.push({name:'ModalOverlayArchive' , params:{videoId: 'fatou.mp4',posterId: 'fatou.png' }})"
          >
          <v-icon>mdi-play</v-icon>
          </v-btn>
          <h2 class="text-uppercase">Cara Mamma</h2>
          <h3>Fatou Ndiaye Diop</h3>
        </div>
      </v-card>

      <v-card
        transparent
        elevation="0"
        class="fill-height px-3"
        @click="$router.push({name:'ModalOverlayArchive' , params:{videoId: 'nezgiba.mp4',posterId: 'nezgiba_b.jpg' }})"
      >
        <v-img height="100%" src="@/assets/nezgiba_b.jpg"></v-img>
        <div class="aut-name">
           <v-btn
              fab
              height="30px"
              right
              width="30px"
              color="blue-grey darken-3"
              elevation="0"
              dark
              @click="$router.push({name:'ModalOverlayArchive' , params:{videoId: 'nezgiba.mp4',posterId: 'nezgiba_b.jpg' }})"
          >
          <v-icon>mdi-play</v-icon>
          </v-btn>
          <h2 class="text-uppercase">Una pentola e un cucchiaio</h2>
          <h3>Nezgiba Salkanovic</h3>
        </div>
      </v-card>

    </VueSlickCarousel>
  </v-sheet>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css"

export default {
  name: "VideoMasonry",
  components: {VueSlickCarousel},
  data(){
    return {
      settings: {
        "responsive": [
          {
            "breakpoint": 768,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "infinite": true,
              "dots": true,
              'arrows': true
            }
          },
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "infinite": true,
              "dots": false,
              'arrows':true
            }
          },
        ]
      }
    }
  }
}
</script>
<style scoped>
  .aut-name{
    position: absolute;
    bottom:0%;
    left: 0;
    color: #1e335e;
    margin:0;
    padding: 20px;
    line-height: 1;
  }
  #sponsor{
      position: absolute;
      bottom: 0;
      width:50%;
  }

@media screen and (min-width: 1400px){

  .slick-slider{
    height: 100%;
    min-height: 600px;
  }
  .slick-track {
    height: 100%;
    min-height: 600px;
    display: flex !important;
    padding: 0 20px;
  }
}
.slick-list {
  height: 100%;
}
.slick-slide {
  height: auto !important;
  margin: 0 20px;
}

.slick-slide > div{
  height: 100%;
}
.content-card{
  position: relative;
  height: 100%;
}
</style>