<template>
  <v-container id="catalogo" class="pa-15 fill-height">
    <v-row class="align-center">
      <v-col class="col-12">
        <v-sheet :min-width="$vuetify.breakpoint.lg ? 900 : '100&'">
          <VueSlickCarousel :dots="true" :slidesToShow="1" v-bind="settings">
            <v-card transparent elevation="0"
              class="pr-md-4 fill-height"
              v-for="book in books" :key="book.author"
            >
              <v-row class="align-center">
                <v-col class="col-12 col-sm-4">
                  <v-img :src='book.cover'></v-img>
                </v-col>
                <v-col class="col-12 col-sm-8">
                  <h2>{{ book.author }}</h2>
                  <h1>{{ book.title }}</h1>
                  <p>{{ book.extract }}</p>
                </v-col>
              </v-row>
            </v-card>
          </VueSlickCarousel>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css"

export default {
  name: "video-carousel",
  components: {VueSlickCarousel},
  data() {
    return {
      books: [
        {
          author: 'Eriselda Qejvanaj',
          title: 'Una cornacchia e un albero che le stava vicino',
          cover: require('../assets/copertine/2023/copertina_8.png'),
          extract: 'Sempre di corsa nei confronti di un tempo tiranno, Eriselda affronta con coraggio il pregiudizio di una società xenofoba e discriminante. A lei è preteso di dimostrare, spiegare e giustificare più delle altre, non le sono concessi errori e le è richiesto sempre di fare bella figura. Attraverso l’immagine di un albero, con le radici in un luogo e la chioma in un altro, Eriselda invita chi ascolta a confrontarsi con la propria paura del diverso e a chiedersi se, quella paura, non sia meglio abbatterla.',
        },
        {
          author: 'Ruth Omobude',
          title: 'A roof for Ruth',
          cover: require('../assets/copertine/2023/copertina_1.png'),
          extract: 'Ruth ha molti amici e molte amiche, una famiglia numerosa che le vuole bene, un figlio che ama alla follia, un lavoro a tempo indeterminato e una madre amorevole che non vede l’ora di vederla. Ha grande coraggio e un’enorme forza interiore. A Ruth però è stato negato un diritto fondamentale: la possibilità di una casa in cui vivere. Di fronte a un bisogno umano elementare come il tetto, c’è la brutale realtà di un sistema che ostacola, complica e pone limiti.'
        },
        {
          author: 'Samira Arouadi',
          title: 'Semi e radici',
          cover: require('../assets/copertine/2023/copertina_2.png'),
          extract: 'Il corpo di una donna è troppo spesso una questione pubblica. Samira lo sa bene. Schiacciata dal giudizio della sua famiglia per una gravidanza che tarda ad arrivare, si trova a sua insaputa in un paese diverso e in una vita che non aveva immaginato. Con grande consapevolezza Samira ci accompagna nella sua storia, dal lavoro di insegnante innovativa in un paesino strettamente legato alla tradizione, per arrivare fin dentro le maglie del dolore per il giudizio degli altri, per un ruolo che le è stato imposto e per le opprimenti decisioni che è stata costretta a subire.',
        },
        {
          author: 'Michèle Djitheu',
          title: 'Perché mi va',
          cover: require('../assets/copertine/2023/copertina_3.png'),
          extract: 'La vita di una migrante deve spesso fare i conti con una società abituata a pensare per categorie ben definite, a inserire le persone in storie già scritte, senza considerarne le peculiarità. Michèle invece rivendica l’unicità di ogni storia attraverso la sua, fatta di determinazione e scelte consapevoli. Una storia che mette in discussione le categorie prestabilite attraverso le quali abbiamo imparato a pensare il mondo. La storia di una donna che cambia paese per un solo motivo, le va.',
        },
        {
          author: 'Hanane Elkani',
          title: 'Alzati e fai la mamma',
          cover: require('../assets/copertine/2023/copertina_4.png'),
          extract: 'La società patriarcale ci ha insegnato a relegare le donne al lavoro di cura, a richiamarle costantemente a una serie di doveri nei confronti dei figli. Anche quando non ce la fanno, anche quando non hanno neppure la forza di alzarsi.In questa lettera alla figlia, Hanane racconta della violenza ostetrica subita il giorno della sua nascita. La domanda è: che vuol dire fare la mamma? Le risposte sono molteplici. Le parole di una donna, mortificata in un giorno tanto atteso e delicato, invitano a una profonda riflessione sulla pericolosa estetica di una maternità perfetta e totalizzante.',
        },
        {
          author: 'Svetlana Salkanovic',
          title: 'Pesci fuor d’acqua',
          cover: require('../assets/copertine/2023/copertina_5.png'),
          extract: 'Nella storia di Svetlana, giovane di vent’anni, c’è tutta la delusione e la rabbia di una ragazza e dei suoi fratelli che si vedono negare l’accesso in una piscina pubblica solo a causa del loro cognome. Attraverso l’impossibilità di entrare in un luogo aperto, si manifesta la violenza di un immaginario comune fortemente discriminante e intriso di pregiudizi ancora troppo radicati.',
        },
        {
          author: 'Shila Gladys',
          title: 'Una storia di migrazione',
          cover: require('../assets/copertine/2023/copertina_7.png'),
          extract: 'I viaggi con un passaporto occidentale sono un’occasione di apprendimento, opportunità lavorative o svago. Sono un mezzo per sottolineare uno status sociale. Ma cosa succede se non si ha un passaporto privilegiato? Shila ci spiega cosa accade quando si è costrette ad affrontare una migrazione. Gli enormi pericoli e le bestiali violenze dei centri di detenzione libici. Una storia che fa male come un pugno, che ci racconta cosa subisce chi non ha avuto il diritto di ricevere un visto per varcare i confini del proprio stato nazione. Ci lascia un senso di vuoto, ci spinge a chiederci cosa fare per abbattere questo muro invisibile ma mortale.',
        },
        {
          author: 'Sajjad',
          title: 'Ricomincio da me',
          cover: require('../assets/copertine/2023/copertina_6.png'),
          extract: '“La polizia è entrata nel tuo appartamento, che hai combinato Sajjad?”. Comincia così la storia di una fuga. Senza neanche poter tornare a casa, attraverso la Turchia e poi il Mediterraneo, Sajjad, un ragazzo curdo poco più che ventenne, scappa dall’Iran per raggiungere l’Europa. Non aveva nessuna di intenzione di andarsene, ci aveva messo vent’anni a costruire la sua vita e gli sembrava di aver fatto un buon lavoro. Ma rimanere non era più possibile e tornare lo è ancora meno. Sajjad racconta il dramma di una vita interrotta un pomeriggio qualsiasi, distrutta all’improvviso da un governo che reprime. E l’enorme fatica di dover ricominciare tutto da capo.',
        }
      ],
      settings: {
        arrows: true,
        "responsive": [
          {
            "breakpoint": 768,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "infinite": true,
              "dots": true,
              'arrows': false
            }
          },
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "infinite": true,
              "dots": true,
              "arrows": false
            }
          },
          
        ]
      }
    }
  }
}
</script>
<style lang="scss">
#catalogo {
  .slick-prev, .slick-next {
    width: 70px;
    height: 70px;
  }

  .slick-prev {
    left: -100px;
  }

  .slick-next {
    right: -100px;
  }

  .slick-prev:before, .slick-next:before {
    font-size: 70px;
    color: darkgrey;
  }
}
</style>