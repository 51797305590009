<template>
  <v-navigation-drawer
      :value="drawer"
      fixed
      temporary
      bottom
      stateless
      >
      <v-btn text @click="$emit('update:drawer', false)">CHIUDI</v-btn>
      <v-list>
        <v-list-item @click="$router.push({name: 'home', path: '/' }).catch(()=>{})"
        v-on:click="$emit('update:drawer', false)">
          <v-list-item-content>
            <v-list-item-title class="text-uppercase">
              Home
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$router.push({name: 'about', path: 'chi-siamo' }).catch(()=>{});"
        v-on:click="$emit('update:drawer', false)">
          <v-list-item-content>
            <v-list-item-title class="text-uppercase">
              Chi siamo
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$router.push({name: 'settimana-razzismo', path: 'settimana-razzismo' }).catch(()=>{})"
         v-on:click="$emit('update:drawer', false)">
          <v-list-item-content>
            <v-list-item-title class="text-uppercase">
              La settimana di azione contro il razzismo
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$router.push({name: 'biblioteca-vivente', path: 'biblioteca-vivente' }).catch(()=>{})"
         v-on:click="$emit('update:drawer', false)">
          <v-list-item-content>
            <v-list-item-title class="text-uppercase">
              Biblioteca vivente
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
         v-on:click="$emit('update:drawer', false)">
          <v-list-item-content three-line>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-title class="text-uppercase" v-bind="attrs"
                  v-on="on">
                    Podcast
                </v-list-item-title>
              </template>
              <v-list>
              <v-list-item
                small
                @click="$router.push({name: 'Podcast2023'})" 
              >
                2023
              </v-list-item>
              <v-list-item
              small
              @click="$router.push({name: 'Podcast2022'})" 
              >
                2022
              </v-list-item>
              </v-list>            
            </v-menu>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
         v-on:click="$emit('update:drawer', false)">
          <v-list-item-content three-line>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-title class="text-uppercase" v-bind="attrs"
                  v-on="on">
                    Catalogo
                </v-list-item-title>
              </template>
              <v-list>
              <v-list-item
                small
                @click="$router.push({name: 'Catalogo2022'})" 
              >
                2022
              </v-list-item>
              <v-list-item
              small
              @click="$router.push({name: 'Catalogo2023'})" 
              >
                2023
              </v-list-item>
              </v-list>            
            </v-menu>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          href="https://www.21luglio.org/dona-ora/"
          class="white--text secondary"
          target="_blank"
          v-on:click="$emit('update:drawer', false)">
          <v-list-item-content>
            <v-list-item-title class="text-uppercase">
              Dona ora
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-spacer></v-spacer>
      <v-img id="main-logo" class="mt-10 px-3" max-width="225px" 
      contain 
      :src="require('../../src/assets/biblioteca_vivente_logo_h.svg')" 
      @click="$router.push({name: 'home', path: '/' }).catch(()=>{})"
      v-on:click="$emit('update:drawer', false)">
      </v-img>
      </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'mobile-drawer',
    props: ['drawer']
}
</script>
<style scoped>

</style>
