<template>
<v-container id="home" class="fill-height px-6 pt-15 pt-lg-0">
  <v-row class="align-center" style="position:relative">
      <v-col cols="12">
        <VideoMasonry></VideoMasonry>
      </v-col>
  </v-row>
</v-container>
</template>

<script>
// @ is an alias to /src
import VideoMasonry from '../components/VideoMasonry.vue'

export default {
  components: { VideoMasonry },
  name: 'PodCast2022'
}

</script>

<style scoped>
</style>
