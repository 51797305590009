<template>
  <v-app>
   <v-app-bar color="white" border="bottom" app elevation="0" min-height="90px" class="py-5">
      <v-img id="main-logo" class="mr-3" max-width="225px" contain :src="require('../src/assets/biblioteca_vivente_logo_h.svg')" @click="$router.push({name: 'home', path: '/' }).catch(()=>{})"></v-img>
      <v-spacer></v-spacer>
      <v-toolbar-items class="text-center justify-center hidden-md-and-down">
        <v-btn small text @click="$router.push({name: 'home', path: '/' }).catch(()=>{})" >Home
        </v-btn>
        <v-btn small text @click="$router.push({name: 'about', path: 'chi-siamo' }).catch(()=>{})" >chi siamo
        </v-btn>
        <v-btn small text @click="$router.push({name: 'settimana-razzismo', path: 'settimana-razzismo' }).catch(()=>{})" >Contro il razzismo
        </v-btn>
        <v-btn small text @click="$router.push({name: 'biblioteca-vivente', path: 'biblioteca-vivente' }).catch(()=>{})" >Biblioteca vivente
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              text
              v-bind="attrs"
              v-on="on"
              small
            >
              Podcast
            </v-btn>
          </template>
          <v-list>
           <v-list-item
           small
           @click="$router.push({name: 'Podcast2023'})" 
           >
            2023
           </v-list-item>
           <v-list-item
            small
            @click="$router.push({name: 'Podcast2022'})" 
           >
            2022
           </v-list-item>
          </v-list>            
        </v-menu>  
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              text
              v-bind="attrs"
              v-on="on"
              small
            >
              Catalogo
            </v-btn>
          </template>
          <v-list>
           <v-list-item
           small
           @click="$router.push({name: 'Catalogo2023'})" 
           >
            2023
           </v-list-item>
           <v-list-item
            small
            @click="$router.push({name: 'Catalogo2022'})" 
           >
            2022
           </v-list-item>
          </v-list>            
        </v-menu>  
         <v-btn
          color="secondary"
          tile
          elevation="0"
          href="https://www.21luglio.org/dona-ora/"
          target="_blank"
          class="white--text"
          small
        >Dona ora</v-btn>
      </v-toolbar-items>
       <v-spacer></v-spacer>
       <v-img class="d-inline-block justify-end" max-width="120px" contain src="@/assets/Logo21LugliosenzaOnlus.png">
      </v-img>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="d-flex d-lg-none"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-main>
      <transition name="slide-fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
    <v-footer color="white" padless class="pa-5">
      <v-row>
        <v-col cols="12" lg="6" class="text-center text-lg-start py-0 py-lg-3"><small>© 2024 ASSOCIAZIONE 21 LUGLIO ETS</small></v-col>
        <v-col cols="12" lg="6" class="text-center text-lg-end py-0 py-lg-3"><small>L.GO FERRUCCIO MENGARONI 29 00133, ROMA | C.F. 97598580583</small></v-col>
      </v-row>
    </v-footer>
    <mobile-drawer :drawer.sync="drawer"></mobile-drawer>
  </v-app>
</template>

<script>
import MobileDrawer from './components/MobileDrawer.vue'

export default {
  components: { MobileDrawer },
  name: 'App',
  data: ()=>({
    drawer: false
  }),
 
};
</script>

<style lang="scss">
/* roboto-100 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('../src/fonts/roboto-v29-latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../src/fonts/roboto-v29-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../src/fonts/roboto-v29-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('../src/fonts/roboto-v29-latin-100.woff') format('woff'), /* Modern Browsers */
       url('../src/fonts/roboto-v29-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../src/fonts/roboto-v29-latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../src/fonts/roboto-v29-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../src/fonts/roboto-v29-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../src/fonts/roboto-v29-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../src/fonts/roboto-v29-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../src/fonts/roboto-v29-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../src/fonts/roboto-v29-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../src/fonts/roboto-v29-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../src/fonts/roboto-v29-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../src/fonts/roboto-v29-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../src/fonts/roboto-v29-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../src/fonts/roboto-v29-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../src/fonts/roboto-v29-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../src/fonts/roboto-v29-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../src/fonts/roboto-v29-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../src/fonts/roboto-v29-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../src/fonts/roboto-v29-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../src/fonts/roboto-v29-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../src/fonts/roboto-v29-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('../src/fonts/roboto-v29-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../src/fonts/roboto-v29-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../src/fonts/roboto-v29-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../src/fonts/roboto-v29-latin-900.woff') format('woff'), /* Modern Browsers */
       url('../src/fonts/roboto-v29-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../src/fonts/roboto-v29-latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Antonio Bold';
    src: url('../src/fonts/Antonio-Bold.ttf');
}

@font-face {
    font-family: 'Antonio Bold';
    src: url('../src/fonts/Antonio-Bold.ttf');
}

@font-face {
    font-family: 'Antonio Medium';
    src: url('../src/fonts/Antonio-Medium.ttf');
}

@font-face {
    font-family: 'Antonio Regular';
    src: url('../src/fonts/Antonio-Regular.ttf');
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
p{
  font-weight: 400;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

#home .slick-track {
  height: 100%;
  min-height: 500px;
  display: flex !important;
}

@media screen and (min-width: 1400px){
  #home .slick-track {
    height: 100%;
    min-height: 600px;
    display: flex !important;
  }
}
@media screen and (max-width:400px){
  #main-logo{
    max-width: 180px !important;
  }
}
#home .slick-list {
  height: 100%;
}
#home .slick-slide {
  height: auto !important;
}

#home .slick-slide > div{
  height: 100%;
}

#home{
  .slick-prev, .slick-next {
    width: 35px;
    height: 35px;
  }
  .slick-prev {
      left: 0;
      top:105%
  }
  .slick-next{
      left: 35px;
      top: 105%;
  }
  .slick-prev:before, .slick-next:before {
    font-size:35px;
    color:darkgrey;
  }
}

</style>