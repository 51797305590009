<template>
<v-container id="home" class="fill-height px-6 pt-15 pt-lg-0">
  <v-row class="align-center" style="position:relative">
      <v-col cols="12">
        <v-sheet>
    <router-view></router-view>
    <VueSlickCarousel :arrows="true" :autoplay="true" :dots="true" :slidesToShow="2" v-bind="settings">
      <v-card 
        transparent 
        elevation="0" 
        class="fill-height px-3"
        @click="$router.push({name:'ModalOverlayArchive2023' , params:{videoId: 'sajjad.mp4',posterId: 'sajjad.jpg' }})"
      >
      <v-img height="100%" src="@/assets/sajjad.jpg" position="left"></v-img>
        <div class="aut-name">
          <v-btn
            fab
            height="30px"
            right
            width="30px"
            color="blue-grey darken-3"
            elevation="0"
            dark
            @click="$router.push({name:'ModalOverlayArchive2023' , params:{videoId: 'sajjad.mp4',posterId: 'sajjad.jpg' }})"
          >
          <v-icon>mdi-play</v-icon>
          </v-btn>
          <h1 class="text-uppercase">Ricomincio da me</h1>
          <h2>Sajjad</h2>
        </div>
      </v-card>
      <v-card 
        transparent 
        elevation="0" 
        class="fill-height px-3"
        @click="$router.push({name:'ModalOverlayArchive2023' , params:{videoId: 'eriselda.mp4',posterId: 'eriselda.jpg' }})"
      >
      <v-img height="100%" src="@/assets/eriselda.jpg" position="left"></v-img>
        <div class="aut-name">
          <v-btn
            fab
            height="30px"
            right
            width="30px"
            color="blue-grey darken-3"
            elevation="0"
            dark
            @click="$router.push({name:'ModalOverlayArchive2023' , params:{videoId: 'eriselda.mp4',posterId: 'eriselda.jpg' }})"
          >
          <v-icon>mdi-play</v-icon>
          </v-btn>
          <h1 class="text-uppercase">Una cornacchia e un albero che le stava vicino</h1>
          <h2>Eriselda Qejvanaj</h2>
        </div>
      </v-card>
      <v-card 
        transparent 
        elevation="0" 
        class="fill-height px-3"
        @click="$router.push({name:'ModalOverlayArchive2023' , params:{videoId: 'shila.mp4',posterId: 'shila.jpg' }})"
      >
      <v-img height="100%" src="@/assets/shila.jpg"></v-img>
        <div class="aut-name">
          <v-btn
            fab
            height="30px"
            right
            width="30px"
            color="blue-grey darken-3"
            elevation="0"
            dark
            @click="$router.push({name:'ModalOverlayArchive2023' , params:{videoId: 'shila.mp4',posterId: 'shila.jpg' }})"
          >
          <v-icon>mdi-play</v-icon>
          </v-btn>
          <h1 class="text-uppercase">Una storia di migrazione</h1>
          <h2>Shila Gladys</h2>
        </div>
      </v-card>
      <v-card 
        transparent 
        elevation="0" 
        class="fill-height px-3"
        @click="$router.push({name:'ModalOverlayArchive2023' , params:{videoId: 'hanane.mp4',posterId: 'hanane.jpg' }})"
      >
      <v-img height="100%" src="@/assets/hanane.jpg"></v-img>
        <div class="aut-name">
          <v-btn
            fab
            height="30px"
            right
            width="30px"
            color="blue-grey darken-3"
            elevation="0"
            dark
            @click="$router.push({name:'ModalOverlayArchive2023' , params:{videoId: 'hanane.mp4',posterId: 'hanane.jpg' }})"
          >
          <v-icon>mdi-play</v-icon>
          </v-btn>
          <h1 class="text-uppercase">Alzati e fai la mamma</h1>
          <h2>Hanane Elkani</h2>
        </div>
      </v-card>
      
      <v-card 
        transparent 
        elevation="0" 
        class="fill-height px-3"
        @click="$router.push({name:'ModalOverlayArchive2023' , params:{videoId: 'ruth.mp4',posterId: 'ruth.jpg' }})"
      >
      <v-img height="100%" src="@/assets/ruth.jpg"></v-img>
        <div class="aut-name">
          <v-btn
            fab
            height="30px"
            right
            width="30px"
            color="blue-grey darken-3"
            elevation="0"
            dark
            @click="$router.push({name:'ModalOverlayArchive2023' , params:{videoId: 'ruth.mp4',posterId: 'ruth.jpg' }})"
          >
          <v-icon>mdi-play</v-icon>
          </v-btn>
          <h1 class="text-uppercase">A roof for Ruth</h1>
          <h2>Ruth Omobude</h2>
        </div>
      </v-card>
      
      <v-card 
        transparent 
        elevation="0" 
        class="fill-height px-3"
        @click="$router.push({name:'ModalOverlayArchive2023' , params:{videoId: 'michele.mp4',posterId: 'michele.jpg' }})"
      >
      <v-img height="100%" src="@/assets/michele.jpg"></v-img>
        <div class="aut-name">
          <v-btn
            fab
            height="30px"
            right
            width="30px"
            color="blue-grey darken-3"
            elevation="0"
            dark
            @click="$router.push({name:'ModalOverlayArchive2023' , params:{videoId: 'michele.mp4',posterId: 'michele.jpg' }})"
          >
          <v-icon>mdi-play</v-icon>
          </v-btn>
          <h1 class="text-uppercase">Perché mi va</h1>
          <h2>Michèle Djitheu</h2>
        </div>
      </v-card>
      
      <v-card 
        transparent 
        elevation="0" 
        class="fill-height px-3"
        @click="$router.push({name:'ModalOverlayArchive2023' , params:{videoId: 'samira.mp4',posterId: 'samira.jpg' }})"
      >
      <v-img height="100%" src="@/assets/samira.jpg"></v-img>
        <div class="aut-name">
          <v-btn
            fab
            height="30px"
            right
            width="30px"
            color="blue-grey darken-3"
            elevation="0"
            dark
            @click="$router.push({name:'ModalOverlayArchive2023' , params:{videoId: 'samira.mp4',posterId: 'samira.jpg' }})"
          >
          <v-icon>mdi-play</v-icon>
          </v-btn>
          <h1 class="text-uppercase">Semi e radici</h1>
          <h2>Samira Arouadi</h2>
        </div>
      </v-card>

      <v-card 
        transparent 
        elevation="0" 
        class="fill-height px-3"
        @click="$router.push({name:'ModalOverlayArchive2023' , params:{videoId: 'svetlana.mp4',posterId: 'svetlana.jpg' }})"
      >
      <v-img height="100%" src="@/assets/svetlana.jpg"></v-img>
        <div class="aut-name">
          <v-btn
            fab
            height="30px"
            right
            width="30px"
            color="blue-grey darken-3"
            elevation="0"
            dark
            @click="$router.push({name:'ModalOverlayArchive2023' , params:{videoId: 'svetlana.mp4',posterId: 'svetlana.jpg' }})"
          >
          <v-icon>mdi-play</v-icon>
          </v-btn>
          <h1 class="text-uppercase">Pesci fuor d'acqua</h1>
          <h2>Svetlana Salkanovic</h2>
        </div>
      </v-card>

    </VueSlickCarousel>
  </v-sheet>
      </v-col>
  </v-row>
</v-container>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css"


export default {
  name: 'PodCast2023',
  components: {VueSlickCarousel},
  data(){
    return {
      settings: {
        "responsive": [
          {
            "breakpoint": 768,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "infinite": true,
              "dots": true,
              'arrows': true
            }
          },
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "infinite": true,
              "dots": false,
              'arrows':true
            }
          },
        ]
      }
    }
  }
}

</script>

<style scoped>
  .aut-name{
    position: absolute;
    font-family: 'Antonio Bold', 'Roboto';
    font-style: italic;
    bottom:0%;
    left: 0;
    color: #fff;
    margin:0;
    padding: 20px;
    line-height: 1;
  }
  .aut-name h2{
    font-family: 'Antonio Regular', 'Roboto';
    font-weight: 400;
  }
  #sponsor{
      position: absolute;
      bottom: 0;
      width:50%;
  }

  @media screen and (min-width: 1400px){

.slick-slider{
  height: 100%;
  min-height: 600px;
}
.slick-track {
  height: 100%;
  min-height: 600px;
  display: flex !important;
  padding: 0 20px;
}
}
.slick-list {
height: 100%;
}
.slick-slide {
height: auto !important;
margin: 0 20px;
}

.slick-slide > div{
height: 100%;
}
.content-card{
position: relative;
height: 100%;
}
</style>
