<template>
  <v-container id="catalogo" class="pa-15 fill-height">
    <v-row class="align-center">
      <v-col class="col-12">
        <v-sheet :min-width="$vuetify.breakpoint.lg ? 900 : '100&'">
          <VueSlickCarousel :arrows="true" :dots="true" :slidesToShow="1" v-bind="settings">
            <v-card transparent elevation="0"
                    class="pr-md-4 fill-height"
                    v-for="book in books" :key="book.author"
            >
              <v-row class="align-center">
                <v-col class="col-12 col-sm-4">
                  <v-img :src='book.cover'></v-img>
                </v-col>
                <v-col class="col-12 col-sm-8">
                  <h2>{{ book.author }}</h2>
                  <h1>{{ book.title }}</h1>
                  <p>{{ book.extract }}</p>
                </v-col>
              </v-row>
            </v-card>
          </VueSlickCarousel>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css"

export default {
  name: "video-carousel",
  components: {VueSlickCarousel},
  data() {
    return {
      books: [
        {
          author: 'Nezgiba Salkanovic',
          title: 'Una pentola e un cucchiaio',
          cover: require('../assets/3_FRONTE.jpg'),
          extract: 'Un mattino d’inverno, prima dell’alba, arrivano i vigili, gli elicotteri, perfino i carabinieri a cavallo. Circondano il campo intero e cominciano a svegliare tutti, a dire che è ora di andare, che li portano al nuovo accampamento. Con le ruspe buttano giù tutto, ma Nezgiba non può andarsene. Suo padre sta morendo e, per una settimana, tra le macerie del campo, la sua famiglia tiene acceso il fuoco funebre. Il corpo del vecchio, dall’ospedale, viene portato per un paio d’ore tra le macerie per l’ultimo saluto e poi Nezgiba, il marito e i loro tre figli si ritrovano nel campo “nuovo”, circondati da persone che non hanno mai visto. Ed è lì, in mezzo al dolore, che a una settimana esatta dalla morte del padre Nezgiba deve preparare il banchetto e provare a radunare tutta la famiglia dispersa, per questo inizio di una vita forse nuova, ma ancora più disperata.',
        },
        {
          author: 'Fatou Ndiaye Diop',
          title: 'Cara Mamma',
          cover: require('../assets/1_FRONTE.jpg'),
          extract: 'Una storia di ordinaria immigrazione, si potrebbe dire. Una donna che viene in Italia dal Senegal per fare la collaboratrice domestica raggiungendo la madre, ma presto viene irretita in un giro losco. Riesce a liberarsene grazie alla sua onestà, alla sua perizia nel fare “treccine” sulla spiaggia per guadagnare “cinque euro puliti (e non trecento sporchi)” e forse ancor di più grazie alla presenza della madre, che le rimane al fianco anche quando di lei sopravvive solo il ricordo, e la sua viva lezione morale.'
        },
        {
          author: 'Leidy Parada',
          title: 'Che nessuno dica che non abbiamo lottato',
          cover: require('../assets/8_FRONTE.jpg'),
          extract: 'Sono una giornalista venezuelana, e sono dovuta scappare dal mio paese per la repressione del governo e per la miseria che lo attanaglia da troppo tempo. In questo libro vi racconto il terrore della violenza, l’angoscia per i miei genitori scappati dalla fame, e l’impegno di coloro che hanno lottato sognando un Venezuela libero. L’amore per la vita, che oggi è anche l’amore per mia figlia, continua a nutrire il mio amore per la verità.',
        },
        {
          author: 'Fatoumata Ndiaye',
          title: 'Una giornata tranquilla',
          cover: require('../assets/2_FRONTE.jpg'),
          extract: 'Basta affacciarsi sul pianerottolo per trovarsi investita dalla malevolenza razzista, oppure salire in ascensore. Ma Fatoumata reagisce con la grinta di una leonessa, anche quando al supermercato fingono di non vedere lei e il suo bambino in passeggino. Anche quando, presentatasi per il suo lavoro di contabile in amministrazione, il personale della portineria insiste a chiederle se non è per caso lei la donna delle pulizie… Un racconto in cui, più delle umiliazioni subite, salta agli occhi la luce fiera dello sguardo della protagonista, pronta a reagire con una scrollata di spalle e una risata ironica, che seppellisce la piccineria di chi prova a insultarla.',
        },
        {
          author: 'Beatrice Atieno Ragot',
          title: 'Perché la mia famiglia mi tiene prigioniera?',
          cover: require('../assets/6_FRONTE.jpg'),
          extract: 'Ho conosciuto mio marito italiano nel mio paese, lui era diverso ai miei occhi, almeno quanto lo ero io ai suoi. Ma ci siamo innamorati, ci siamo sposati, e per un po’ io non ho pensato di venire in Italia. Ma poi è stato lui a rientrare, e io l’ho seguito, come deve fare una moglie. Avevamo già un figlio, quando lui se ne è andato per sempre. Me l’ha portato via un incidente d’auto. Come moglie, avrei dovuto ereditare la metà del suo patrimonio, l’altra metà essendo per mio figlio, ma i miei suoceri mi hanno di fatto segretata in casa, costringendomi a vivere con loro fin quando non ho ceduto loro la mia eredità, pur di riprendermi la mia vita di donna libera.',
        },
        {
          author: 'Kwanza Musi Dos Santos',
          title: 'La banalità del razzismo',
          cover: require('../assets/4_FRONTE.jpg'),
          extract: 'Se hai la pelle nera, e sei cresciuta in Italia, il rischio è sempre quello: stai nella casa delle vacanze, sale un fattorino che deve sistemare qualche tubo, e se sei tu ad aprire ti chiederà se sei la domestica, e che avrebbe bisogno di parlare con i padroni di casa. Quando gli spieghi che la padrona di casa sei tu, ti guarda perplesso, senza capire perché tu stai fumando di rabbia… Il razzismo è banale perché è incompreso da chi lo pratica, che non è detto sia una persona “cattiva”, proprio perché questo modo di pensare dipende dal fatto che viviamo in una società razzista, e quindi siamo tutti razzisti, fino a prova contraria.',
        },
        {
          author: 'Akossiwa Sheillea Anani',
          title: 'Raccontamela giusta!',
          cover: require('../assets/7_FRONTE.jpg'),
          extract: 'Sheillea è una donna colta quanto bella, spiritosa quanto intelligente e davvero non ne può più della stupidità delle domande che spesso le fanno guardando il suo colore, e immaginando che provenga da un’Africa un po’ misteriosa e un po’ stracciona. Per questo il suo libro è una deliziosa presa in giro, ma senza troppi giri di parole. Ridete con Sheillea, e per una volta ridete di voi stessi.',
        },
        {
          author: 'Zeneb',
          title: 'Ma ero femmina',
          cover: require('../assets/5_FRONTE.jpg'),
          extract: 'Sono nata, ma ero femmina. Ero piccola e solare, ma ero femmina. Ero forte, forte come l’acciaio, ma ero femmina. Ero veloce, veloce come il vento che soffia a volte nel paese dei miei antenati, ma ero femmina. La famiglia di mio padre non me l’ha mai perdonata, questa cosa. Neanche mio padre: ero obbediente, ma per lui non andava bene, perché ero femmina. E allora ho smesso di essere obbediente, ma non certo di essere femmina. Sono madre, ora, e il mio cuore non è abbastanza grande per tutto il mio amore, perché ho una figlia. Femmina senza nessuno a dirle ma.',
        }
      ],
      settings: {
        "responsive": [
          {
            "breakpoint": 768,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "infinite": true,
              "dots": true,
              'arrows': false
            }
          },
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "infinite": true,
              "dots": true,
              "arrows": true
            }
          },
        ]
      }
    }
  }
}
</script>
<style lang="scss">
#catalogo {
  .slick-prev, .slick-next {
    width: 70px;
    height: 70px;
  }

  .slick-prev {
    left: -100px;
  }

  .slick-next {
    right: -100px;
  }

  .slick-prev:before, .slick-next:before {
    font-size: 70px;
    color: darkgrey;
  }
}
</style>