import { render, staticRenderFns } from "./Podcast2022.vue?vue&type=template&id=23f5bab2&scoped=true&"
import script from "./Podcast2022.vue?vue&type=script&lang=js&"
export * from "./Podcast2022.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23f5bab2",
  null
  
)

export default component.exports