<template>
<v-container id="home" class="fill-height px-6 pt-15 pt-lg-0" fluid>
  <v-row class="align-center" style="position:relative">
    <v-col cols="12" lg="6"> 
      <div class="home">
        <h2 class="text-uppercase">La Settimana di azione contro il razzismo</h2>
        <h1 class="text-h4 text-md-h3 text-lg-h2 text-uppercase pr-lg-15">Un libro non si giudica dalla copertina!</h1>
        <v-btn class="mt-3"
          large
          tile
          elevation="0"
          color="primary"
          @click="$router.push({name: 'settimana-razzismo', path:'settimana-razzismo'})"
        >Scopri di più</v-btn>
      </div>
      <v-row id="sponsor" class="align-center d-none d-lg-flex">
        <v-col cols="2">
          <v-img max-width="110px" src="@/assets/logo_unar.svg"></v-img>
        </v-col>
        <v-col cols="10" class="pl-0">
          <p class="text-uppercase mb-0"><small><b>Il progetto ha ricevuto un finanziamento nazionale pubblico dall'UNAR<br/>Ufficio Nazionale Antidiscriminazioni Razziali nell'ambito delle attività della XVIII Settimana di azione contro il razzismo</b></small></p>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="6">
     <video-carousel />
    </v-col>
  </v-row>
  <v-row class="align-center mt-10 d-flex d-lg-none">
      <v-col cols="12" class="text-center">
        <v-img contain class="text-center mx-auto" max-width="90px" src="@/assets/logo_unar.svg"></v-img>
        <p class="text-uppercase mb-0"><small><b>Il progetto ha ricevuto un finanziamento nazionale pubblico dall'UNAR<br/>Ufficio Nazionale Antidiscriminazioni Razziali nell'ambito delle attività della XVIII Settimana di azione contro il razzismo</b></small></p>
      </v-col>
    </v-row>
    <router-view></router-view>
</v-container>
</template>

<script>
// @ is an alias to /src
import VideoCarousel from '../components/VideoCarousel.vue'

export default {
  components: { VideoCarousel,  },
  name: 'HomeIndex'
}

</script>

<style scoped>
#sponsor{
  position: absolute;
  bottom: 20px;
  width:50%;
}

@media screen and (min-width: 1300px){
  #sponsor{
    position: absolute;
    bottom: 45px;
    width:50%;
  }
}
</style>
