<template>
    <v-overlay class="custom-overlay" >
    <div id="modal">
      <v-btn
      class="mx-2"
      fab
      color="dark"
      id="modal-close-button"
      @click="$router.go(-1)"
    >
      <v-icon dark>
        mdi-close
      </v-icon>
    </v-btn>
      <video controls autoplay="autoplay" width="100%" height="auto" :poster="posterSrc">
        <source :src="iframeSrc" type="video/mp4">
      </video>
    </div>
</v-overlay>
</template>

<script>

export default {
  name: 'modal-overlay',
  computed: {
    iframeSrc: function() {
      return require('@/assets/' + this.$route.params.videoId)
    },
    posterSrc: function(){
      if(this.$route.params.videoId == 'nezgiba.mp4'){
        return require('@/assets/nezgiba_b.jpg')
      }
      else if(this.$route.params.videoId == 'fatou.mp4'){ 
         return require('@/assets/fatou.png')
      }
      else if(this.$route.params.videoId == 'leidy.mp4'){ 
         return require('@/assets/leidy.png')
      }
      else if(this.$route.params.videoId == 'beatrice.mp4'){ 
         return require('@/assets/beatrice.png')
      }
      else if(this.$route.params.videoId == 'kwanza.mp4'){ 
         return require('@/assets/kwanza.png')
      }
      else if(this.$route.params.videoId == 'fatoumata.mp4'){ 
         return require('@/assets/fatoumata.png')
      }
      else if(this.$route.params.videoId == 'sheillea.mp4'){ 
         return require('@/assets/sheillea.png')
      }
      else if(this.$route.params.videoId == 'zeneb.mp4'){ 
         return require('@/assets/zeneb.png')
      }
      else if(this.$route.params.videoId == 'svetlana.mp4'){ 
         return require('@/assets/svetlana.jpg')
      }
      else if(this.$route.params.videoId == 'samira.mp4'){ 
         return require('@/assets/samira.jpg')
      }
      else if(this.$route.params.videoId == 'michele.mp4'){ 
         return require('@/assets/michele.jpg')
      }
      else if(this.$route.params.videoId == 'ruth.mp4'){ 
         return require('@/assets/ruth.jpg')
      }
      else if(this.$route.params.videoId == 'hanane.mp4'){ 
         return require('@/assets/hanane.jpg')
      }
      else if(this.$route.params.videoId == 'shila.mp4'){ 
         return require('@/assets/shila.jpg')
      }
      else if(this.$route.params.videoId == 'eriselda.mp4'){ 
         return require('@/assets/eriselda.jpg')
      }
      else if(this.$route.params.videoId == 'sajjad.mp4'){ 
         return require('@/assets/sajjad.jpg')
      }
      return require('@/assets/' + this.$route.params.videoId)
    }
  }
}
</script>
<style scoped>
#modal-close-button{
  position: absolute;
  top: -20px;
  left:-20px;
  z-index: 2;
}
#modal{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  z-index: 2;
  width: 90%;
}

.custom-overlay >>> .v-overlay__content {
    width: 80%;
  }
</style>
