<template>
  <v-sheet>
    <VueSlickCarousel :arrows="true" :autoplay="true" :dots="true" :slidesToShow="1" v-bind="settings">
      <v-card 
        transparent 
        elevation="0" 
        class="fill-height rounded-xl"
        @click="$router.push({name:'ModalOverlay' , params:{videoId: 'sajjad.mp4',posterId: 'sajjad.jpg' }})"
      >
      <v-img height="100%" src="@/assets/sajjad.jpg"></v-img>
        <div class="aut-name">
          <v-btn
            fab
            height="30px"
            right
            width="30px"
            color="blue-grey darken-3"
            elevation="0"
            dark
            @click="$router.push({name:'ModalOverlay' , params:{videoId: 'sajjad.mp4',posterId: 'sajjad.jpg' }})"
          >
          <v-icon>mdi-play</v-icon>
          </v-btn>
          <h1 class="text-uppercase">Ricomincio da me</h1>
          <h2>Sajjad</h2>
        </div>
      </v-card>
    </VueSlickCarousel>
  </v-sheet>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css"

export default {
  name: "video-carousel",
  components: {VueSlickCarousel},
  data(){
    return {
      settings: {
        "responsive": [
          {
            "breakpoint": 768,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "infinite": true,
              "dots": true,
              'arrows': true
            }
          },
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "infinite": true,
              "dots": false,
              'arrows':true
            }
          },
        ]
      }
    }
  }
}
</script>
<style scoped>
  .aut-name{
    position: absolute;
    font-family: 'Antonio Bold', 'Roboto';
    font-style: italic;
    bottom:0%;
    left: 0;
    color: #fff;
    margin:0;
    padding: 20px;
    line-height: 1;
  }
  .aut-name h2{
    font-family: 'Antonio Regular', 'Roboto';
    font-weight: 400;
  }

  #sponsor{
      position: absolute;
      bottom: 0;
      width:50%;
  }

@media screen and (min-width: 1400px){

  .slick-slider{
    height: 100%;
    min-height: 600px;
  }
  .slick-track {
    height: 100%;
    min-height: 600px;
    display: flex !important;
  }
}
.slick-list {
  height: 100%;
}
.slick-slide {
  height: auto !important;
}

.slick-slide > div{
  height: 100%;
}
.content-card{
  position: relative;
  height: 100%;
}
</style>